@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';

.root {
  position: relative;
  padding-bottom: 24px;

  & :global(.carousel.carousel-slider) {
    position: static;
  }
}

.arrowBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;
  z-index: 10;

  width: 24px;
  height: 24px;
  min-width: 0;
  padding: 0;

  border: none;
  border-radius: 50%;
  outline: none;

  color: var(--text-30);
  background-color: var(--shape-10);

  &:disabled,
  &:disabled:active {
    padding: 0;
  }
}

.sliderBtnPrev {
  left: calc(50% - 20px - 12px);
}

.sliderBtnNext {
  left: calc(50% + 20px - 12px);
}

.description {
  color: var(--text-30);
  font-size: $font-size-small;
  font-weight: 400;
  line-height: 20px;
}

.slide {
  border: none;
  background-color: var(--shape-10);
}
